import React, { useEffect } from 'react'
import { Container,Row,Col } from 'reactstrap';

import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/pg.css'
import Subtitle from './Subtitle.jsx';
import Testimonials from './Testimonials.jsx';
import img1 from './girl-min.png'
import {Helmet}from"react-helmet";
import ga from '../images/ga.png';
import gd from '../images/gd.png';
import gc from '../images/gc.png';
import gb from '../images/gb.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slidee from './Slidee.js';

 const Ab =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>About us</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_topb' id='top__main'>
      <div className='top__m' >
        <h1 data-aos="zoom-in" style={{paddingTop:"220px"}}>About<span id='id_c3'>  &nbsp;Us</span> </h1>   
          </div>
  
    </section>

<section className='main__middle'>
  <div className='main__middle2'> 
  <h2 data-aos="zoom-in">Who  <span id='id_c3'>We Are</span> </h2>
  <div className='row_m2a'>
<div className='col_m2a'>
        <img src={gc} alt="" data-aos="fade-right"/>
    </div>
    <div className='col_m2a' data-aos="fade-left">

   <br /><br />
   <p>
   At the heart of 10 Rounds Kickboxing lies a core belief: every woman possesses an innate
strength waiting to be unlocked. As a premier instructor-led kickboxing gym dedicated
exclusively to women, we are more than just a fitness center; we are a sanctuary of
empowerment, resilience, and community. Our founding principle is to create a safe, supportive,
and dynamic environment where women from all walks of life can come together to challenge
themselves, learn self-defense, and embrace their full potential.
<br /><br />
Building on our commitment to transform lives, we offer a range of classes designed to cater to
every woman's journey, whether she's stepping into the gym for the first time or she's a
seasoned athlete looking to refine her skills. Our expert female instructors, each a champion in
her own right, are not just teachers but mentors who guide, inspire, and motivate our members
to exceed their own expectations. At 10 Rounds, you're not just joining a gym; you're becoming
part of a community that celebrates women's strength, courage, and the power of sisterhood.
Join us, and let's embark on this journey of empowerment together.
    </p> 
      </div>
</div>


  </div>
  <br />
  <br />
  <br />
 </section>


<section className='middle__main_2'>
<div className='middle__main_2a'>
<div className='row_m2'>
<div className='col_m2' data-aos="fade-right">
  <h2>We have a lot </h2>
  <h2 id='id_c4'>of Experience</h2>
  <p>
  Central to the enriching experience at 10 Rounds Kickboxing is the guidance of our esteemed
instructor, Jane Doe, whose expertise and passion for kickboxing illuminate our gym. Jane's
approach is both personal and profound, blending technical mastery with an inspiring dedication
to each member's growth and well-being. With years of experience in both competing and
coaching, she embodies the spirit of resilience and empowerment we champion. Under her
leadership, our sessions are more than just workouts; they are transformative experiences that
foster confidence, discipline, and camaraderie among our members. Jane's commitment to
creating an inclusive, motivational space ensures that every woman who walks into our gym
feels valued, challenged, and supported on her path to self-discovery and physical prowess. At
10 Rounds, Jane Doe is not just an instructor; she is a mentor, a motivator, and the heart of our
community, making each session a step forward in our members' journeys to empowerment.
  </p>
    <button>
About Us
    </button>

</div>
<div className='col_m2'>
    <img src={gd} alt="" data-aos="fade-left" />
</div>
</div>
</div>
</section>

<section style={{display:"none"}} className='main__middle'>
    <div className='main__middle4'>
    <h2>OUR PROFEESIONAL</h2>
    <h2 id='id_c3'>TRAINERS</h2>
    <p>Whether you're looking to set up a home gym or enhance your current workout routine</p>

     </div>

   </section>
  
   <section style={{display:"none"}} className='main__middleddd'>

    <div className='main__middle5'>
    <h2>Voices of <span id='id_c3'>Empowerment</span> </h2>
    
  
    <Slidee/>

         </div>

   </section>
   

   <section className='main__middle6'>
  <section className='las__sec' data-aos="zoom-in">
   
    
    <div className='form__main'>
      <h2>Let’s start Your</h2>
      <h2>Training Now</h2>
      <br />
      <input type="text" placeholder='Enter Your Name' />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="text" placeholder='Phone' />
      <br /><br />
      <input type="text" placeholder='Your Email Address' />
      <br />
      <button>
        Lets Join Now
      </button>
  
      </div> 
     
  </section> 
 
   </section>
   <section className='main__middle7' data-aos="zoom-in">
    <div className='main__middle8'>
    <h2>Let’s start Your <span>Training Now</span> </h2>
   <p>gSign up now to celebrate our grand opening this summer and enjoy an exclusive 50% off your first three months!</p>
   <input type="text" placeholder='Enter Your Name' />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="text" placeholder='Phone' />
      <br /><br />
      <input type="text" placeholder='Your Email Address' />
      <br />
    
    <button>Lets Join Now</button>
  
    </div>
   </section>
    </div>
  )
}
export default Ab;