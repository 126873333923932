import React, { useEffect } from 'react'
import { Container,Row,Col } from 'reactstrap';

import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import Subtitle from './Subtitle.jsx';
import Testimonials from './Testimonials.jsx';
import img1 from './girl-min.png'
import {Helmet}from"react-helmet";
import ga from '../images/ga.png';
import gd from '../images/gd.png';
import gc from '../images/gc.png';
import gb from '../images/gb.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slidee.js';

 const Contact =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>contact</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_topb' id='top__main'>
      <div className='top__m' data-aos="zoom-in">
        <h1 style={{paddingTop:"220px"}}>Contact<span id='id_c3'>  &nbsp;Us</span> </h1>   
          </div>
  
    </section>



<section className='middle__main_2'>
<div className='middle__main_2a'>
<div className='row_m2ab'>
<div className='col_m2ab' data-aos="fade-right">
<h2>Get in <span id='id_c3'>Touch</span></h2>
<p>Join our sisterhood of strength and empowerment.</p>

<form action="">
<input type="text" placeholder='Name' />
<input type="text" placeholder='Email' />
<input type="text" placeholder='Phone Number' />
<input type="text" placeholder='How did you find us?' />
<br />
<button>send</button>
</form>
</div>
<div className='col_m2ab'>

</div>
</div>
</div>
</section>

<section className='main__middle' data-aos="zoom-in">
  <div className='main__middle2'> 
<br />
  <iframe title='map' className='mapcon' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.394300527652!2d-95.46552238254641!3d29.737299690832184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16c2cf13989%3A0x9fd2bbab78247d62!2s2800%20Post%20Oak%20Blvd%2C%20Houston%2C%20TX%2077056%2C%20USA!5e0!3m2!1sen!2s!4v1672409898497!5m2!1sen!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 



  </div>
  <br />
  <br />
  <br />
 </section>


    </div>
  )
}
export default Contact;