const people = [
    {
      id: 1,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959131/person-2_ipcjws.jpg',
      name: 'Samantha',
      title: 'Testimonial 1',
      quote:"Joining 10 Rounds was a game-changer for me. Not only did I find a physical strength I didn't know I had, but I also found a sense of belonging. The instructors are incredibly supportive, and the community here lifts each other up. It's more than a gym; it's a family that encourages you to be your best self.",
    },
    {
      id: 2,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959131/person-2_ipcjws.jpg',
      name: 'Maria',
      title: 'Testimonial 2',
      quote:
        "I was hesitant at first, thinking I might not fit in or keep up. But from my very first class at 10 Rounds, I felt welcomed and empowered. The instructors tailored the workouts to my level, and I've seen incredible progress in my fitness and confidence. This gym truly understands the power of women supporting women.",
    },
    {
      id: 3,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959131/person-2_ipcjws.jpg',
      name: 'Lena',
      title: 'Testimonial 3',
      quote:
        "10 Rounds Kickboxing has transformed not just my body, but my whole outlook on life. The allwomen environment is incredibly empowering, and learning kickboxing has given me a newfound confidence that extends beyond the gym. I'm so grateful for this community and the amazing instructors who make every workout challenging and fun.",
    },
    
  ];
  
  export default people;
  