import React, { useEffect } from 'react'
import { Container,Row,Col } from 'reactstrap';

import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import '../components/Styles/blog.css'
import Subtitle from './Subtitle.jsx';
import Testimonials from './Testimonials.jsx';
import img1 from './girl-min.png'
import {Helmet}from"react-helmet";
import ga from '../images/ga.png';
import gd from '../images/gd.png';
import gc from '../images/gc.png';
import gb from '../images/gb.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide from './Slidee.js';

 const Blog =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Engineering, Interior Remodeling & Construction Company - Jayco Construction</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
      <div className='top__m'>
        <h1 style={{paddingTop:"220px"}}>Our<span id='id_c3'>  &nbsp;Blogs</span> </h1>   
          </div>
  
    </section>



<section className='main__middle'>
  <div className='main__middle2'> 
  <br /><br />
<div className='row_m2ac'>
<div className='col_m2ac'>
<img src={gd} alt="" />
<h2>Neque porro quisquam est qui dolorem ipsum quia</h2>
<p>August 20, 2023</p>
</div>
<div className='col_m2ac'>
<img src={gd} alt="" />
<h2>Neque porro quisquam est qui dolorem ipsum quia</h2>
<p>August 20, 2023</p>
</div>
<div className='col_m2ac'>
<img src={gd} alt="" />
<h2>Neque porro quisquam est qui dolorem ipsum quia</h2>
<p>August 20, 2023</p>
</div>
</div>
<div className='row_m2ac'>
<div className='col_m2ac'>
<img src={gd} alt="" />
<h2>Neque porro quisquam est qui dolorem ipsum quia</h2>
<p>August 20, 2023</p>
</div>
<div className='col_m2ac'>
<img src={gd} alt="" />
<h2>Neque porro quisquam est qui dolorem ipsum quia</h2>
<p>August 20, 2023</p>
</div>
<div className='col_m2ac'>
<img src={gd} alt="" />
<h2>Neque porro quisquam est qui dolorem ipsum quia</h2>
<p>August 20, 2023</p>
</div>
</div>

  </div>
  <br />
  <br />
  <br />
 </section>



    </div>
  )
}
export default Blog;