import React, { useEffect } from 'react'
import { Container,Row,Col } from 'reactstrap';

import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/pg.css'
import Subtitle from './Subtitle.jsx';
import Testimonials from './Testimonials.jsx';
import img1 from './girl-min.png'
import {Helmet}from"react-helmet";
import ga from '../images/ga.png';
import gd from '../images/gd.png';
import gc from '../images/gc.png';
import gb from '../images/gb.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slidee from './Slidee.js';

 const Our =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title></title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_topb' id='top__main'>
      <div data-aos="zoom-in" className='top__m'>
        <h1 style={{paddingTop:"220px"}}>Our <span id='id_c3'>Programs</span> </h1>   
          </div>
  
    </section>

<section className='main__middle'>
  <div className='main__middle2'> 

  <div className='row_m2a'>
<div className='col_m2a' data-aos="fade-right">
        <img src={ga} alt="" />
    </div>
    <div className='col_m2a' data-aos="fade-left">
   <h2>Kickboxing: Instructor-Led 
<span id='id_c3'>Classes for All Women</span> </h2>
   <br />
   <p>
   At 10 Rounds Kickboxing, our flagship program offers instructor-led kickboxing classes 
designed exclusively for women. These sessions, guided by expert female instructors, provide a 
comprehensive workout that combines strength, flexibility, and cardiovascular health. 
Kickboxing is not only an effective way to improve physical fitness and learn self-defense 
techniques but also a powerful tool for boosting confidence and mental toughness. Our classes 
cater to women of all skill levels, ensuring a supportive and empowering environment for 
everyone. Whether you're looking to challenge yourself, achieve personal fitness goals, or 
simply find a fun way to stay active, our kickboxing classes offer the perfect blend of intensity, 
learning, and community.

    </p> 
       </div>
</div>


  </div>
  <br />
  <br />
  <br />
 </section>


<section className='middle__main_2'>
<div className='middle__main_2a'>
<div className='row_m2'>
<div className='col_m2' data-aos="fade-right">
  <h2 style={{fontSize:"40px",fontWeight:"400",lineHeight:"48px",textAlign:"center",fontFamily:"Montserrat"}}>Resistance Training:
</h2>
  <h2 id='id_c4'  style={{fontSize:"40px",fontWeight:"400",lineHeight:"48px",textAlign:"center",fontFamily:"Montserrat"}}> Building Strength and Confidence</h2>
  <br />
  <p  style={{fontSize:"16px",fontWeight:"300",lineHeight:"24px",textAlign:"left",fontFamily:"Poppins"}}>Resistance training at 10 Rounds Kickboxing is more than just lifting weights; it's about building 
a stronger, more resilient you. This program is designed to improve muscle strength, 
endurance, and tone, contributing to overall physical health and well-being. Our approach to 
resistance training focuses on functional movements that enhance your ability to perform 
everyday activities with ease while reducing the risk of injury. With access to a variety of 
equipment and personalized guidance from our trainers, members can work towards specific 
fitness goals in a supportive, women-only gym environment. Embrace the challenge and 
experience how resistance training can transform your body, boost your confidence, and 
empower you in all aspects of life.</p>


</div>
<div className='col_m2' data-aos="fade-left">
    <img src={gd} alt="" />
</div>
</div>
</div>
</section>

 <section className='main__middle'>
  <div className='main__middle2'> 

  <div className='row_m2a'>
<div className='col_m2a' data-aos="fade-right">
        <img src={gc} alt="" />
    </div>
    <div className='col_m2a' data-aos="fade-left">
   <h2>Aerobics, Zumba, and Yoga:
<span id='id_c3'>Enhancing Your Wellness Journey</span> </h2>
   <br /><br />
   <p>Dive into our diverse offering of Aerobics, Zumba, and Yoga classes, each instructor-led and 
tailored to enrich your fitness journey with joy, vitality, and balance. These programs are 
designed to complement your kickboxing and resistance training by offering holistic benefits that 
impact both body and mind. Aerobics and Zumba inject fun and energy into your workout 
routine, combining high-energy music with movements that improve cardiovascular health, 
enhance stamina, and burn calories. Yoga, on the other hand, offers a tranquil counterbalance, 
promoting flexibility, strength, and mental clarity through mindful practices and breath control. 
Together, these classes support weight management, reduce stress, and contribute to a 
happier, healthier life. Embrace the variety and find the perfect blend of activities to nourish your 
body, challenge your limits, and uplift your spirit</p>
    </div>
</div>


  </div>
  <br />
  <br />
  <br />
 </section>

 <section className='middle__main_2' style={{display:"none"}}>
<div className='middle__main_2a'>
<div className='row_m2'>
<div className='col_m2'>
<img src={gb} alt="" />
  

</div>
<div className='col_m2'>
    
    <h2>Self-Defense </h2>
  <h2 id='id_c4'>Focus</h2>
  <p>In quisque nunc dictumst etiam pellentesque et. Vel malesuada diam lorem tellus. Amet mauris feugiat ipsum natoque odio donec. Sit at lacus consequat justo odio condimentum dui. Faucibus id blandit feugiat mi tellus sit etiam donec aliquam. Dictumst egestas ut facilisi vel.
<br /><br />
Sem consequat fermentum pellentesque risus purus quis gravida. Nulla porttitor ultrices facilisis non commodo diam morbi cursus eu. Semper ut in mauris gravida id cursus urna. Magnis vulputate orci risus felis eget lectus morbi. Et cursus mauris condimentum pretium arcu sed dignissi</p>
</div>
</div>
</div>
</section>

  
   <section style={{display:"none"}} className='main__middle'>
    <div className='main__middle4'>
    <h2>OUR PROFEESIONAL</h2>
    <h2 id='id_c3'>TRAINERS</h2>
    <p>Whether you're looking to set up a home gym or enhance your current workout routine</p>
     </div>
   </section>
  
   <section style={{display:"none"}} className='main__middle'>
    <div className='main__middle4'>
    <h2>OUR PROFEESIONAL</h2>
    <h2 id='id_c3'>TRAINERS</h2>
    <p>Whether you're looking to set up a home gym or enhance your current workout routine</p>

     </div>

   </section>
  
   <section style={{display:"none"}} className='main__middleddd'>

    <div className='main__middle5'>
    <h2>Voices of <span id='id_c3'>Empowerment</span> </h2>
    
  
    <Slidee/>

         </div>

   </section>

   <section data-aos="zoom-in" className='main__middle6'>
  <section className='las__sec'>
   
    
    <div className='form__main'>
      <h2>Let’s start Your</h2>
      <h2>Training Now</h2>
      <br />
      <input type="text" placeholder='Enter Your Name' />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="text" placeholder='Phone' />
      <br /><br />
      <input type="text" placeholder='Your Email Address' />
      <br />
      <button>
        Lets Join Now
      </button>
  
      </div> 
     
  </section> 
 
   </section>
   <section className='main__middle7'>
    <div className='main__middle8'>
    <h2>Let’s start Your <span>Training Now</span> </h2>
   <p>Sign up now to celebrate our grand opening this summer and enjoy an exclusive 50% off your first three months!</p>
   <input type="text" placeholder='Enter Your Name' />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="text" placeholder='Phone' />
      <br /><br />
      <input type="text" placeholder='Your Email Address' />
      <br />
    
    <button>Lets Join Now</button>
  
    </div>
   </section>
    </div>
  )
}
export default Our;