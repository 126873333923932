import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import AboutUs from '../pages/AboutUs';
import Contact from '../pages/Contact';
import GeneralEngineering from '../pages/GeneralEngineering';
import Home from '../pages/Home';
import Home2 from '../pages/Home2';
import InteriorRemodeling from '../pages/InteriorDesign';
import OurTeam from '../pages/OurTeam';
import Services from '../pages/Services';
import Structural from '../pages/Structural';
import Our from '../pages/Our';
import Ab from '../pages/Ab';
import Blog from '../pages/Blog';
import Slidee from '../pages/Slidee.js';


const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Navigate to='/Home'/>} />
      <Route path='home' element={<Home/>} />

      <Route path='aboutus' element={<Ab/>} />
      <Route path='our-team' element={<Our/>} />
      <Route path='contact' element={<Contact/>} />
      <Route path='Blog' element={<Blog/>} />
    
    </Routes>
  )
}
export default Routers;