import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './boxlogo.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
        <div className='footer___width'>
          
        <img src={logo} alt="logo" />
          <br /><br />
          <a href="home">Home</a>
         <a href="aboutus">About Us</a> 
          <a href="contact">Contact Us</a> 
         
        </div>
      </section> 
    </>
  )
}
export default Footer;