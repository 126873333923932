import React, { useEffect } from 'react'
import { Container,Row,Col } from 'reactstrap';
import Slide from './Slidee.js';
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import Subtitle from './Subtitle.jsx';
import Testimonials from './Testimonials.jsx';
import ga from '../images/ga.png';
import img1 from './girl-min.png'
import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slidee from './Slidee.js';


 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>kickboxing</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
      <div  className='top__m'>
      <h1  data-aos="zoom-in">Discover Your Power </h1>
  
<p data-aos="zoom-in">Empower and Protect: Your Safe Space to Kickbox and Thrive</p>
  <button data-aos="zoom-in">
    Lets join now
    </button>
    <div style={{display:"none"}} className='roww'>
    <div className='colll'>
      <h2>20+</h2>
      <p>Years of Experience</p>
    </div>
    <div className='colll'>
      <h2>20+</h2>
      <p>Years of Experience</p>
    </div>
    <div className='colll'>
      <h2>20+</h2>
      <p>Years of Experience</p>
    </div>
    </div>
       </div>
  
    </section>

<section className='main__middle'>
  <div className='main__middle2'> 
  <h2 data-aos="zoom-in">Training <span id='idtext'>Programs</span> </h2>
  <br />
 <p data-aos="zoom-in">Gym workouts offer a versatile and customisable experience, allowing everyone to set specific fitness goals.</p>
<br /><br />
<div className='row__fir'>
<div data-aos="fade-right" className='col__fir'>
<h2>01</h2>
<h3>Kickboxing Fundamentals & Mastery

</h3>
<p>Begin your journey with our Kickboxing Fundamentals, designed for newcomers to build a solid base in technique, stance, and basic moves. As you grow, transition into Mastery Kickboxing, where advanced techniques, combinations, and sparring bring your skills to competitive levels. This seamless progression from beginner to advanced levels, all under the expert guidance of our female instructors, ensures a supportive path to empowerment and fitness excellence.</p>
</div>
<div data-aos="fade-left" className='col__fir'>
<h2>02</h2>
<h3>Strength Building & Functional Fitness

</h3>
<p>Our Strength Building class focuses on muscle development and endurance through diverse resistance training. As you evolve, our Functional Fitness sessions integrate these strengths into daily life activities, enhancing your core stability, balance, and flexibility. This combination ensures a well-rounded approach to physical health, where gains in the gym translate to improved life performance, guided by our dedicated trainers in a motivating, women-only environment.</p>
</div>  
</div>



<div className='row__fir'>
<div className='col__fir' data-aos="fade-right">
<h2>03</h2>
<h3>Dynamic Aerobics & Zumba Celebration

</h3>
<p>Experience the joy and energy of fitness with our Dynamic Aerobics class, a high-intensity program designed to boost cardiovascular health and stamina. Then, let the rhythm move you in our Zumba Celebration, blending dance and exercise into a fun-filled workout that feels more like a party. Both classes promise to elevate your mood, improve your health, and connect you with a community of women committed to fitness and fun.
</p>
</div>
<div className='col__fir' data-aos="fade-left">
<h2>04</h2>
<h3>Yoga for Balance & Power
</h3>
<p>Start with Gentle Yoga, where beginners and those seeking a soothing practice can find flexibility, balance, and peace in a supportive setting. As your confidence and abilities grow, challenge yourself with Power Yoga, a more intense class that focuses on strength, stamina, and the dynamic flow of movement. Together, these yoga programs offer a holistic approach to physical and mental wellness, aligning body and spirit in the pursuit of inner and outer strength.</p>
</div>  
</div>

  </div>
  <br />
  <br />
  <br />
 </section>


<section className='middle__main_2'>
<div className='middle__main_2a'>
<div className='row_m2'>
<div className='col_m2' data-aos="fade-right">
  <h2>We have a lot of</h2>
  <h2 id='id_c4'>Experience</h2>
  <p>10 Rounds Fitness and Kickboxing is a beacon of empowerment for women. Our gym, led by
experienced female instructors, offers kickboxing classes designed to empower, challenge, and
inspire women of all fitness levels. It's a place where strength meets community, providing a
supportive environment tailored specifically for women seeking to boost their confidence and
physical prowess.
<br /><br />
Our aim is to foster a community where safety and empowerment are paramount, making every
member feel like part of a collective journey towards personal greatness. At 10 Rounds, you'll
find more than just a workout; you'll discover a path to becoming your most powerful self. Join
us and experience the difference in a gym that puts women's strength and solidarity first.</p>

</div>
<div className='col_m2' data-aos="fade-left">
  <img src={ga} alt="" />
</div>
</div>
</div>
</section>
   
   <section style={{display:"none"}} className='main__middle'>
    <div data-aos="zoom-in" className='main__middle4'>
    <h2>OUR PROFEESIONAL</h2>
    <h2 id='id_c3'>TRAINERS</h2>
    <p>Whether you're looking to set up a home gym or enhance your current workout routine</p>

     </div>

   </section>
  
   <section style={{display:"none"}} className='main__middleddd'>

    <div className='main__middle5'>
    <h2>Voices of <span id='id_c3'>Empowerment</span> </h2>
    
  
    <Slidee/>

         </div>

   </section>
   
   

  <section className='main__middle6'>
  <section data-aos="zoom-in" className='las__sec'>
   
    
    <div className='form__main'>
      <h2>Let’s start Your</h2>
      <h2>Training Now</h2>
      <br />
      <input type="text" placeholder='Enter Your Name' />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="text" placeholder='Phone' />
      <br /><br />
      <input type="text" placeholder='Your Email Address' />
      <br />
      <button>
        Lets Join Now
      </button>
  
      </div> 
     
  </section> 
 
   </section>
   <section className='main__middle7'>
    <div className='main__middle8'>
    <h2>Let’s start Your <span>Training Now</span> </h2>
   <p>Sign up now to celebrate our grand opening this summer and enjoy an exclusive 50% off your first three months!</p>
   <input type="text" placeholder='Enter Your Name' />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input type="text" placeholder='Phone' />
      <br /><br />
      <input type="text" placeholder='Your Email Address' />
      <br />
    
    <button>Lets Join Now</button>
  
    </div>
   </section>
    </div>
  )
}
export default Home;